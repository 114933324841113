<template>
    <header
        class="super-app-bar"
    >
        <GlobalPageBanner
            v-if="bannerState"
            class="super-app-bar-top"
            :is-mobile="isMobile"
            :type="bannerType"
            @on-hide="onBannerHide()"
        >
            <GlobalPageBannerContent
                :is-mobile="isMobile"
                :type="bannerType"
                @on-hide="onBannerHide()"
            ></GlobalPageBannerContent>
        </GlobalPageBanner>

        <v-sheet
            ref="elSuperAppBar"
            class="super-app-bar-container w-100"
        >
            <v-toolbar
                tag="div"
                color="transparent"
                class="pl-4"
                :class="{ 'pl-3': isMobile }"
                height="56"
                flat
            >
                <!-- Logo -->
                <NuxtLink
                    to="/"
                    class="d-flex fill-height"
                    aria-label="Home"
                >
                    <Logo
                        static-size="header"
                        :height="logoHeight"
                    />
                </NuxtLink>

                <!-- Dropdown Menus -->
                <MainMenu
                    v-if="!isMobile"
                    class="flex-grow-0 flex-shrink-1 ml-4"
                    :nav-items="navItems"
                ></MainMenu>

                <!-- Menu Buttons -->
                <div
                    class="
                        d-flex
                        align-center
                        justify-end
                        flex-shrink-0
                        flex-grow-1
                    "
                    :class="{
                        'pr-2': !isMobile,
                        'gap-1': isMobile
                    }"
                >
                    <UserBtn
                        @on-click="toggleDrawerMenu('user', true)"
                    ></UserBtn>
                    <CartBtn
                        @on-click="toggleDrawerMenu('cart', true)"
                    ></CartBtn>
                    <v-app-bar-nav-icon
                        v-if="isMobile"
                        color="secondary"
                        @click="toggleDrawerMenu('main', true)"
                        aria-label="Menu"
                    />
                </div>
            </v-toolbar>
        </v-sheet>
    </header>
</template>

<script lang="ts" setup>
    import type { MenuItem, SectionStyle } from '~/types';
    import type { VSheet } from 'vuetify/components';
    import { useDisplay } from 'vuetify';
    import {
        useIsMobile,
        useGlobalPageBanner,
        toggleDrawerMenu
    } from '~/composables';
    import Logo from '~/components/Logo/Logo.vue';
    import MainMenu from '~/components/header/menus/MainMenu/MainMenu.vue';

    const UserBtn = defineAsyncComponent(() => import('~/components/header/menus/UserBtn/UserBtn.vue'));
    const CartBtn = defineAsyncComponent(() => import('~/components/header/menus/CartBtn/CartBtn.vue'));
    const GlobalPageBanner = defineAsyncComponent(() => import('~/components/GlobalPageBanner/GlobalPageBanner.vue'));
    const GlobalPageBannerContent = defineAsyncComponent(() => import('~/components/GlobalPageBannerContent/GlobalPageBannerContent.vue'));

    defineProps<{
        navItems?: Nilable<MenuItem[]>;
        headerConfig?: SectionStyle['light' | 'dark'];
    }>();

    const { name: displayName } = useDisplay();
    const { isMobile } = useIsMobile();
    const { bannerState, bannerType, toggleBanner } = useGlobalPageBanner();
    const elSuperAppBar = ref<VSheet>();
    let observer: IntersectionObserver;

    const logoHeight = computed<number>(() => {
        switch (displayName.value) {
            case 'sm':
                return 28;
            case 'xs':
                return 22;
            default:
                return 32;
        }
    });

    function onBannerHide(): void {
        toggleBanner(false);
    }

    async function onIntersect(entries: IntersectionObserverEntry[]): Promise<void> {
        entries.forEach((item) => {
            item.target.classList.toggle('scrolled', item.intersectionRatio >= 1);
        });
    }

    onMounted(() => {
        if (elSuperAppBar.value) {
            observer = new IntersectionObserver(
                onIntersect,
                {
                    threshold: [1],
                    rootMargin: `-56px 0px 0px 0px`,
                    root: document.scrollingElement
                }
            );
            observer.observe(elSuperAppBar.value.$el);
        }
    });

    onBeforeUnmount((): void => {
        if (elSuperAppBar.value && observer) {
            observer.unobserve(elSuperAppBar.value.$el);
        }
    });
</script>

<style lang="scss">
.super-app-bar {
    display: contents;

    &-container {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 999;
        background: $top-menu-background-color;

        &.scrolled {
            box-shadow: 0 2px 4px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
                0 4px 5px 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
                0 1px 10px 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
        }
    }

    .logo-centered {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
    }

    .v-toolbar__content > .v-btn:first-child {
        margin-inline-start: unset;
    }
}
</style>
