<template>
    <v-menu
        ref="elMenu"
        v-model="menuState"
        content-class="main-menu-dropdown"
        offset="14"
        open-delay="120"
        close-delay="120"
        rounded="b-lg t-0"
        :transition="false"
        :reverse-transition="false"
        scroll-strategy="block"
        :open-on-click="isTouch"
        close-on-content-click
        open-on-hover
    >
        <template #activator="{ props, isActive }">
            <MainMenuItemButton
                @click.capture.prevent
                v-bind="{ ...props, to: item.path }"
                :is-active="isActive"
            >
                {{ item.label }}
            </MainMenuItemButton>
        </template>
        
        <v-sheet
            width="100%"
            height="auto"
            class="
                main-menu-dropdown-inner
                overflow-y-auto
                flex-column
                position-relative
                pa-0
                rounded-b
                rounded-t-0
            "
        >
            <div
                class="d-flex flex-column align-center pa-4"
            >

                <!-- Top "All" Button -->
                <v-row
                    class="w-100"
                    dense
                >
                    <v-col>
                        <v-btn
                            :to="item.path!"
                            class="opacity-100"
                            variant="plain"
                            color="secondary"
                            role="menuitem"
                            size="x-large"
                            tile
                            block
                            exact
                        >
                            All {{ item.label }}
                        </v-btn>
                    </v-col>
                </v-row>

                <!-- Sub Menu -->
                <div
                    class="fit-content"
                >
                    <v-row
                        v-if="item.items && item.items.length && item.items[0]?.items"
                        class="flex-nowrap"
                        no-gutters
                    >
                        <v-col
                            v-for="child in item.items"
                            :key="`menu-child-with-children-${child.id}`"
                            cols="auto"
                            class="flex-grow-1 flex-shrink-1"
                        >
                            <v-list
                                class="top-menu-list"
                                role="menu"
                                dense
                            >
                                <v-list-item
                                    class="top-menu-list-header pl-4"
                                    :to="child.path!"
                                    role="menuitem"
                                    color="secondary"
                                >
                                    <strong>{{ child.label.toUpperCase() }}</strong>
                                </v-list-item>
                                <v-list-item
                                    v-for="gchild in child.items || []"
                                    :key="gchild.id!"
                                    :to="gchild.path!"
                                    role="menuitem"
                                    color="secondary"
                                >
                                    {{ gchild.label }}
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                    <v-row
                        v-else
                        class="flex-nowrap"
                        no-gutters
                    >
                        <v-col
                            cols="auto"
                            class="flex-grow-1 flex-shrink-1"
                        >
                            <v-list
                                class="top-menu-list"
                                dense
                            >
                                <v-list-item
                                    v-for="child in item.items || []"
                                    :key="`menu-child-no-children-${child.id}`"
                                    :to="child.path!"
                                    role="menuitem"
                                    color="secondary"
                                >
                                    {{ child.label }}
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-sheet>
    </v-menu>
</template>

<script lang="ts" setup>
    import type { VMenu } from 'vuetify/components';
    import type { MenuItem } from '~/types';
    import MainMenuItemButton from '~/components/header/menus/MainMenuItemButton/MainMenuItemButton.vue';


    withDefaults(
        defineProps<{
            item: MenuItem;
            contentClass?: string;
            backgroundColor?: Nilish<string>;
            isTouch?: boolean;
        }>(),
        {
            contentClass: 'main-menu-dropdown',
            backgroundColor: null,
            isTouch: false
        }
    );

    const elMenu = ref<InstanceType<typeof VMenu>>();
    const menuState = ref<boolean>(false);

    // NOTE: Terrible workaround for Vuetify bug where menu doesn't close on item click (v3.3.3+)
    const unWatchMenuState = watch(
        menuState,
        (newVal) => {
            if (newVal === false) {
                elMenu.value?.activatorEl?.dispatchEvent(new MouseEvent('mouseleave', {bubbles: true}));
            }
        },
        {
            immediate: true
        }
    );

    onBeforeUnmount(() => {
        unWatchMenuState();
    });
</script>

<style lang="scss">
.top-menu {
    &-list {
        background: transparent;

        @at-root a#{&}-header {
            text-decoration: underline;
        }
    }
}

.main-menu-dropdown {
    left: 0 !important;
    min-width: 100% !important;
    width: 100%;

    // Adds a buffer between button and menu so that
    // the menu doesn't close when the mouse leaves the button
    &:before {
        content: '';
        position: absolute;
        top: -14px;
        left: 0;
        width: 100%;
        height: 14px;
        background-color: transparent;
        z-index: 1;
    }

    .v-subheader {
        font-size: 0.9rem;
        font-weight: bold;
    }

    a.v-list-item {
        font-size: 1rem;
    }

    &-inner {
        transition: all 1s;
        background: $top-menu-background-color;
        clip-path: inset(0 -20px -20px -20px);
    }

    &-more {
        position: sticky;
        bottom: 0;
        text-align: center;
        background-color: rgb(var(--v-theme-surface));

        &.scrolled-max {
            opacity: 0.2;
        }
    }
}
</style>
